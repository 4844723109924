type Environment = 'test' | 'development' | 'staging' | 'production';

const getEnvironment = (): Environment => {
  switch (process.env.NEXT_PUBLIC_ENVIRONMENT) {
    case 'test':
      return 'test';
    case 'development':
      return 'development';
    case 'staging':
      return 'staging';
    case 'production':
      return 'production';
    default:
      return (process.env.NODE_ENV as Environment | undefined) ?? 'staging';
  }
};

const coralogixConfig = {
  appVersion: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || '',
  applicationName: process.env.NEXT_PUBLIC_CORALOGIX_APPLICATION || 'kittl',
  authenticationToken:
    process.env.NEXT_PUBLIC_CORALOGIX_AUTHENTICATION_TOKEN || '',
  debug: process.env.NEXT_PUBLIC_CORALOGIX_RUM_DEBUG === 'true',
  environment: process.env.NEXT_PUBLIC_CORALOGIX_ENV || process.env.NODE_ENV,
  isEnabled: process.env.NEXT_PUBLIC_CORALOGIX_RUM_ENABLED === 'true',
  traceDomains: process.env.NEXT_PUBLIC_CORALOGIX_RUM_TRACE_DOMAINS || '',
  traceHeaderEnabled:
    process.env.NEXT_PUBLIC_CORALOGIX_RUM_TRACE_HEADER_ENABLED === 'true',
  traceIgnoredDomains:
    process.env.NEXT_PUBLIC_CORALOGIX_RUM_TRACE_IGNORED_DOMAINS || '',
  trackSessionsWithErrors:
    process.env.NEXT_PUBLIC_CORALOGIX_RUM_TRACK_ON_ERROR_ENABLED === 'true',
};

export const config = {
  ENVIRONMENT: getEnvironment(),
  BEDROCK_EMBEDDING_MODEL_ID: 'amazon.titan-embed-image-v1',
  CORALOGIX: coralogixConfig,
  DATABASE_CONNECTION_TIMEOUT_MS: process.env.DATABASE_CONNECTION_TIMEOUT_MS
    ? parseInt(process.env.DATABASE_CONNECTION_TIMEOUT_MS)
    : 5000,
  KITTL_LOGGER_ENABLED: process.env.NEXT_PUBLIC_KITTL_LOGGER_ENABLED
    ? process.env.NEXT_PUBLIC_KITTL_LOGGER_ENABLED.toLowerCase() === 'true'
    : false,
  SENTRY_ISSUES_BY_USER_ID_URL:
    'https://kittl.sentry.io/issues/?project=5716618&project=5777487&project=5739581&project=6136256&project=4507652984930304&query=user.id%3A{userId}&referrer=issue-list&sort=date&statsPeriod=7d&utc=true&viewId=default0',
  PERFORMANCE_METRICS_BY_USER_ID_URL: `https://search-search-v2-metrics-production-7huhpsdqp7ai6e3ocyjzj4ymma.eu-central-1.es.amazonaws.com/_dashboards/app/data-explorer/discover#?_a=(discover:(columns:!(_source),isDirty:!f,sort:!()),metadata:(indexPattern:'0d31d0b0-7039-11ef-a25a-7f064c2a65e9',view:discover))&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-1d,to:now))&_q=(filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'0d31d0b0-7039-11ef-a25a-7f064c2a65e9',key:userId.keyword,negate:!f,params:(query:{userId}),type:phrase),query:(match_phrase:(userId.keyword:{userId})))),query:(language:kuery,query:''))`, // prod performance metrics url only
  PERFORMANCE_METRICS_BY_DESIGN_ID_URL: `https://search-search-v2-metrics-production-7huhpsdqp7ai6e3ocyjzj4ymma.eu-central-1.es.amazonaws.com/_dashboards/app/data-explorer/discover#?_a=(discover:(columns:!(_source),isDirty:!f,sort:!()),metadata:(indexPattern:'0d31d0b0-7039-11ef-a25a-7f064c2a65e9',view:discover))&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-1d,to:now))&_q=(filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'0d31d0b0-7039-11ef-a25a-7f064c2a65e9',key:designId.keyword,negate:!f,params:(query:{designId}),type:phrase),query:(match_phrase:(designId.keyword:{designId})))),query:(language:kuery,query:''))`, // prod performance metrics url only
  RELEASE_SHA: process.env.RELEASE_SHA || 'unset',
};
