/**
 * Template related events
 */
export const TEMPLATE_EVENTS = {
  ADD_TAG: 'cms:template:addTag',
  REMOVE_TAG: 'cms:template:removeTag',
  ADD_USE_CASE: 'cms:template:addUseCase',
  REMOVE_USE_CASE: 'cms:template:removeUseCase',
  ADD_SUB_USE_CASE: 'cms:template:addSubUseCase',
  REMOVE_SUB_USE_CASE: 'cms:template:removeSubUseCase',
  UPDATE_NAME: 'cms:template:updateName',
  UPDATE_DESCRIPTION: 'cms:template:updateDescription',
  FILL_DETAILS_AI: 'cms:template:fillDetailsAi',
  FILL_DETAILS_MANUAL: 'cms:template:fillDetailsManual',
  CHECK_ORIGINALITY: 'cms:template:checkOriginality',
  CHECK_DESIGN_STATE: 'cms:template:checkDesignState',
} as const;
